const DIRECTORY_DEPARTMENTS_ROUTES = {
    path: 'directory/department',
    name: 'directory-department',
    redirect: { name: 'department-list' },
    component: () => import('@/views/pages/Directories/department/DirectoryDepartmentPage.vue'),
    children: [
        {
            path: 'list',
            name: 'department-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/department/list/DirectoryDepartmentListPage.vue')
        },
        {
            path: ':departmentId',
            name: 'current-departments-department',
            meta: { transition: 'slide-right' },
            redirect: { name: 'current-department' },
            component: () => import('@/views/pages/Directories/department/current/DirectoryDepartmentCurrentPage.vue'),
            children: [
                {
                    path: 'department',
                    name: 'current-department',
                    meta: { transition: 'slide-left' },
                    component: () => import('@/views/pages/Directories/department/current/tabs/main/tab.tsx')
                },
                {
                    path: 'attributes',
                    name: 'current-department-attributes',
                    component: () => import('@/views/pages/Directories/department/current/tabs/attributes/tab.tsx')
                },
                {
                    path: 'basic',
                    name: 'current-department-basic',
                    component: () => import('@/views/pages/Directories/department/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'classifiers',
                    name: 'current-department-classifiers',
                    component: () => import('@/views/pages/Directories/department/current/tabs/classifiers/tab.tsx')
                },
                {
                    path: 'comparison',
                    name: 'current-department-comparison',
                    component: () => import('@/views/pages/Directories/department/current/tabs/comparison/tab.tsx')
                },
                {
                    path: 'schedule',
                    name: 'current-department-schedule',
                    component: () => import('@/views/pages/Directories/department/current/tabs/schedule/DepartmentSchedulePage.vue')
                },
                {
                    path: 'store',
                    name: 'current-department-store',
                    component: () => import('@/views/pages/Directories/department/current/tabs/store/tab.tsx')
                },
                {
                    path: 'data-source',
                    name: 'current-department-data-source',
                    component: () => import('@/views/pages/Directories/department/current/tabs/dataSource/tab.tsx')
                },
                {
                    path: 'installation',
                    name: 'current-department-installation',
                    component: () => import('@/views/pages/Directories/department/current/tabs/installation/tab.tsx')
                },
                {
                    path: 'transition-story',
                    name: 'current-department-transition-story',
                    component: () => import('@/views/pages/Directories/department/current/tabs/transitionStory/tab.tsx')
                },
                {
                    path: 'logs-history',
                    name: 'logs-history',
                    component: () => import('@/views/pages/Directories/department/current/tabs/logsHistory/tab.tsx')
                },
            ]
        }
    ]
};

export default DIRECTORY_DEPARTMENTS_ROUTES;
