const DIRECTORY_EKG_ROUTES = {
	path: "directory/ekg",
	name: "directory-ekg",
	redirect: { name: "ekg-list" },
	component: () => import("@/views/pages/Directories/ekg/DirectoryEkgPage.vue"),
	children: [
		{
			path: "list",
			name: "ekg-list",
			meta: { transition: "slide-left" },
			component: () => import("@/views/pages/Directories/ekg/list/DirectoryEkgListPage.vue"),
		},
		{
			path: ":ekgId",
			name: "current-ekg-ekg",
			meta: { transition: "slide-right" },
			// redirect: { name: 'current-event-basic' },
			component: () => import("@/views/pages/Directories/ekg/current/DirectoryEkgCurrentPage.vue"),
			children: [
				{
					path: "ekg",
					name: "current-ekg",
					component: () => import("@/views/pages/Directories/ekg/current/tabs/basic/tab.tsx"),
				},
				{
					path: "contractors",
					name: "current-ekg-contractors",
					component: () => import("@/views/pages/Directories/ekg/current/tabs/contractor/tab.tsx"),
				},
				{
					path: "logs-history",
					name: "current-ekg-logs-history",
					component: () => import("@/views/pages/Directories/ekg/current/tabs/logsHistory/tab.tsx"),
				},
			],
		},
	],
};

export default DIRECTORY_EKG_ROUTES;